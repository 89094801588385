<template>
  <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card mt-8">
    <v-list-item one-line class="pt-1">
      <v-list-item-content>
        <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">VACCINES REGISTRATIONS CREATED, (last 10)</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-card-text class="bg-white text-area" style="min-height: auto">
      <v-simple-table style="width: 100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">First Name</th>
              <th class="text-left">Last Name</th>
              <th class="text-left">Date of Birth</th>
              <th class="text-left">Phone Number</th>
              <th class="text-left">Print</th>
            </tr>
          </thead>
          <tbody v-if="$store.state.vaccineRegistrationPage.registrations.length > 0">
            <tr
              v-for="(subject, i) in $store.state.vaccineRegistrationPage.registrations"
              :key="subject.name + '-' + i"
              :class="subject.patientCaseId"
            >
              <td>{{ subject.formData.firstName }}</td>
              <td>{{ subject.formData.lastName }}</td>
              <td>{{ formatDate(subject.formData.dateOfBirth) }}</td>
              <td>{{ subject.formData.phoneNumber }}</td>
              <td>
                <v-btn @click="printSubject(subject)" small outlined color="primary"><v-icon left>mdi-printer</v-icon>Print</v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <!-- print registration component -->
    <PrintVaccineRegistration
      :formData="formData"
      :patientCase="patientCaseId"
      :noEmail="noEmail"
      :consentFormData="consentFormData"
      :id="'PrintVaccineRegistrationTable'"
    />
  </v-card>
</template>

<script>
import PrintVaccineRegistration from '@/views/VaccineViews/VaccineRegistrationPage/components/PrintVaccineRegistration.vue';
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    PrintVaccineRegistration,
  },
  data() {
    return {
      formData: null,
      consentFormData: null,
      patientCaseId: null,
      noEmail: null,
    };
  },
  mounted() {
    const registrations = JSON.parse(localStorage.getItem('dvg_vaccine_registrations'));
    if (registrations && registrations.length > 0) this.$store.commit('SET_VACCINE_STORED_REGISTRATIONS', registrations.slice(0, 10));
  },
  methods: {
    printSubject(subject) {
      this.formData = subject.formData;
      this.consentFormData = subject.consentFormData;
      this.patientCaseId = subject.patientCaseId;
      this.noEmail = subject.noEmail;

      setTimeout(() => {
        this.$htmlToPaper('PrintVaccineRegistrationTable');
      }, 400);
    },
  },
};
</script>

<style>
</style>