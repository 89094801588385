<template>
  <v-container fluid v-if="hasAccessToPage">
    <!-- Inventory table -->
    <v-row justify="center">
      <v-col cols="12" sm="12" md="10" lg="8">
        <VaccineRegistrationForm />
      </v-col>
    </v-row>
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
import VaccineRegistrationForm from '@/views/VaccineViews/VaccineRegistrationPage/components/VaccineRegistrationForm.vue';

export default {
  mixins: [SharedFunctions],
  components: {
    NoAccessMessage,
    VaccineRegistrationForm
  },
  data() {
    return {
      hasAccessToPage: false
    };
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
  }
};
</script>

<style></style>
