<template>
  <v-dialog v-model="showModal" persistent max-width="290">
    <v-card>
      <v-card-title class="headline"> Vaccine Proccess </v-card-title>
      <v-card-text> Would you like to take the user through the vaccine proccess? </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog()"> No </v-btn>
        <v-btn color="primary" @click="goToCheckoutPage()"> Yes </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['showModal', 'patientCaseId'],
  methods: {
    closeDialog() {
      this.$emit('resetRegistrationForm');
      this.$parent.showRedirectVaccineCheckoutModal = false;
    },
    goToCheckoutPage() {
      this.$router.push(`/vaccine-scan/${this.patientCaseId}`);
    },
  },
};
</script>

<style>
</style>