<template>
  <div>
    <v-card-title class="title pt-0"><h3>Vaccine Registration</h3> </v-card-title>
    <v-divider class="mb-8" />
    <ConsentForm v-if="showConsentForm" @nextStep="nextStepConsentForm" />
    <v-card class="elevation-0" v-else>
      <v-form v-model="formValid" ref="form-vaccine-registration">
        <v-container>
          <v-card class="pa-8" elevation="0">
            <!-- name/ last name -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  v-model="formData.firstName"
                  :rules="[(v) => !!v || 'This field is required']"
                  class="required"
                  label="First name"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="formData.lastName"
                  :rules="[(v) => !!v || 'This field is required']"
                  class="required"
                  label="Last name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- dob / gender -->
            <v-row class="align-center">
              <v-col cols="12" md="5" offset-md="1">
                <v-dialog ref="dialogBirthDate" v-model="dialogBirthDate" :close-on-content-click="false" transition="scale-transition" width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      class="required"
                      :rules="dobRules"
                      :value="computedBirthDate"
                      required
                      clearable
                      label="Birthday"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker ref="picker" v-model="birthDate" :max="new Date().toISOString().substr(0, 10)" min="1900-01-01" @change="save">
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col cols="12" md="5">
                <v-radio-group v-model="formData.gender" class="mt-0 mb-0 required" row :rules="[(v) => !!v || 'This field is required']">
                  <template v-slot:label>
                    <div>Gender</div>
                  </template>
                  <v-radio label="Female" value="female"></v-radio>
                  <v-radio label="Male" value="male"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>

            <!-- email -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  v-model="formData.email"
                  label="Email"
                  :rules="!noEmail ? emailRules : []"
                  :disabled="noEmail"
                  type="email"
                  hint="eg. john.doe@mail.com"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-checkbox v-model="noEmail" :label="'No Email'"></v-checkbox>
              </v-col>
            </v-row>

            <!-- phone /address  -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  v-model="formData.phoneNumber"
                  label="Phone"
                  type="tel"
                  :rules="[(v) => !!v || 'This field is required']"
                  hint="eg. 593 1234"
                  class="required"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  v-model="formData.accommodationAddress"
                  label="Full Address"
                  hint="eg. Savaneta 100A"
                  :rules="[(v) => !!v || 'This field is required']"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- azv / doctor  -->
            <v-row>
              <v-col cols="12" md="5" offset-md="1">
                <v-text-field
                  v-model="formData.azvNumber"
                  label="AZV Number"
                  hint="eg. 1079592800"
                  :loading="checkingAzvNumber"
                  :disabled="checkingAzvNumber"
                  @input="validateAzvNumberFunctionLocal($event)"
                  type="text"
                  :error-messages="errorAzvAreadyInUse"
                  :rules="[(v) => validateAzvNumberLocal || !v || 'AZV number not valid']"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-autocomplete
                  clearable
                  :disabled="doctorOptions.length == 0"
                  :loading="doctorOptions.length == 0"
                  v-model="formData.doctorEntry.id"
                  data-lpignore="true"
                  :items="doctorOptions"
                  label="Doctor"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-alert type="error" v-if="formError" dense class="mt-8"> Please fill in all required fields </v-alert>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="showConsentForm = true" outlined large :disabled="submittingForm"> Restart </v-btn>
              <v-btn color="primary" @click="submitForm()" large :disabled="submittingForm" :loading="submittingForm"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-container>
      </v-form>
    </v-card>

    <!-- print registration component -->
    <PrintVaccineRegistration
      :formData="formData"
      :patientCase="patientCaseId"
      :noEmail="noEmail"
      :consentFormData="consentFormData"
      :id="'PrintVaccineRegistration'"
    />

    <!-- regstrations to print -->
    <v-row justify="center">
      <v-col cols="12" sm="12" lg="12" xl="9">
        <VaccineRegistrationsCreatedTable />
      </v-col>
    </v-row>

    <!-- redirect to checkout modal -->
    <RedirectToCheckoutDialog
      :showModal="showRedirectVaccineCheckoutModal"
      :patientCaseId="patientCaseId"
      @resetRegistrationForm="resetRegistrationForm"
    />
  </div>
</template>

<script>
import moment from 'moment';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import PrintVaccineRegistration from '@/views/VaccineViews/VaccineRegistrationPage/components/PrintVaccineRegistration.vue';
import ConsentForm from '@/views/VaccineViews/VaccineRegistrationPage/components/ConsentForm.vue';
import VaccineRegistrationsCreatedTable from '@/views/VaccineViews/VaccineRegistrationPage/components/VaccineRegistrationsCreatedTable.vue';
import RedirectToCheckoutDialog from '@/views/VaccineViews/VaccineRegistrationPage/components/RedirectToCheckoutDialog.vue';

export default {
  mixins: [SharedFunctions],
  components: { PrintVaccineRegistration, ConsentForm, VaccineRegistrationsCreatedTable, RedirectToCheckoutDialog },
  data() {
    return {
      dialogBirthDate: false,
      birthDate: null,
      formValid: null,
      noEmail: false,
      submittingForm: false,
      validateAzvNumberLocal: true,
      azvNumberCache: null,
      checkingAzvNumber: false,
      errorAzvAreadyInUse: null,
      showConsentForm: true,
      consentFormData: null,
      formError: false,
      patientCaseId: null,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        gender: null,
        phoneNumber: null,
        dateOfBirth: null,
        accommodationAddress: null,
        travelArrival: null, //registration date
        intoQuarantineDate: null,
        azvNumber: null,
        travelOrigin: 'MFA_VACCINE_REQUEST',
        doctorEntry: {
          id: null
        }
      },
      formDataPrestine: null,
      storedRegistrations: [],
      emailRules: [(v) => !!v || 'E-mail is required', (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid', (v) => this.checkForDotEmail(v)],
      dobRules: [(v) => !!v || 'E-mail is required', (v) => this.isOfAge(v, this.$store.state.vaccine.minimumVaccinationAge)],
      showRedirectVaccineCheckoutModal: false
    };
  },
  watch: {
    dialogBirthDate(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'));
    },
    noEmail(n) {
      if (n) this.formData.email = null;
    }
  },
  computed: {
    doctorOptions() {
      return this.$store.state.doctorOptions;
    },
    computedBirthDate() {
      return this.birthDate ? moment(this.birthDate).format('D-MMMM-YYYY') : '';
    }
  },
  created() {
    //cache form data
    this.formDataPrestine = JSON.stringify(this.formData);
    //get doctors
    if (this.doctorOptions.length == 0) this.$store.dispatch('getDoctorOptions');
    //cache azv number for edit validation
    if (this.formData.azvCardNumber) this.azvNumberCache = this.formData.azvCardNumber;
  },
  methods: {
    submitForm() {
      this.formError = false;
      if (!this.$refs['form-vaccine-registration'].validate()) {
        this.formError = true;
        return;
      }
      let formData = this.cleanUpDataToSubmit();
      this.submittingForm = true;
      this.$store
        .dispatch('createNewLocalSubjectPaper', formData)
        .then(async (data) => {
          if (data) {
            this.patientCaseId = data.patientCaseId;
            const consentPayload = {
              patientCase: { id: this.patientCaseId },
              data: JSON.stringify(this.consentFormData)
            };
            const consentReturn = await this.$store.dispatch('REQUEST_VACCINE', consentPayload);
            if (consentReturn) {
              this.print();
              this.saveSubjectToLocalStorage({
                formData: formData,
                consentFormData: this.consentFormData,
                patientCaseId: this.patientCaseId,
                noEmail: this.noEmail
              });
            }
          }
        })
        .finally(() => {
          this.submittingForm = false;
        });
    },
    save(date) {
      this.formData.dateOfBirth = date + 'T13:00:00.000Z[UTC]';
      this.dialogBirthDate = false;
    },
    validateAzvNumberFunctionLocal(value) {
      if (this.validateAzvNumber(value)) {
        this.validateAzvNumberLocal = true;
        this.checkAzvExists(value);
      } else if (value == '') {
        this.validateAzvNumberLocal = true;
        this.errorAzvAreadyInUse = null;
      } else this.validateAzvNumberLocal = false;
    },
    checkAzvExists(value) {
      if (this.azvNumberCache != value) {
        this.checkingAzvNumber = true;
        if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = true;
        this.azvNumberAlreadyRegistered(value)
          .then((result) => {
            if (result) {
              this.errorAzvAreadyInUse = 'AZV number already in use';
            } else this.errorAzvAreadyInUse = null;
          })
          .finally(() => {
            this.checkingAzvNumber = false;
            if (this.newCase) this.$parent.$parent.$parent.$parent.checkingAzvNumber = false;
          });
      } else this.errorAzvAreadyInUse = null;
    },
    cleanUpDataToSubmit() {
      //clean up
      let dataToPost = JSON.parse(JSON.stringify(this.formData));
      if (!dataToPost.azvNumber) delete dataToPost.azvNumber;
      if (!dataToPost.doctorEntry.id) delete dataToPost.doctorEntry;

      //check if no email is checked
      if (this.noEmail) dataToPost.email = this.makeUid(10) + '@dvgapp.org';

      //registration date
      const todayMoment = moment().local().format('YYYY-MM-DD[T]HH:mm:ss.000') + 'Z';
      dataToPost.travelArrival = todayMoment;
      dataToPost.intoQuarantineDate = todayMoment;
      return dataToPost;
    },
    saveSubjectToLocalStorage(dataToSave) {
      let registrations = [];
      let storedRegistration = JSON.parse(localStorage.getItem('dvg_vaccine_registrations'));
      if (storedRegistration) {
        registrations = storedRegistration;
        registrations.unshift(dataToSave);
      } else {
        registrations[0] = dataToSave;
      }

      this.$store.commit('SET_VACCINE_STORED_REGISTRATIONS', registrations.slice(0, 10));
      localStorage.setItem('dvg_vaccine_registrations', JSON.stringify(registrations.slice(0, 10)));
    },
    print() {
      this.$store.commit('SET_SHOW_LOADER', { action: true, text: 'Opening print page' });
      setTimeout(() => {
        this.$store.commit('SET_SHOW_LOADER', { action: false, text: null });
        this.$htmlToPaper('PrintVaccineRegistration', null, () => {
          this.showDialog = false;
          this.$store.commit('SET_SNACKBAR', { open: true, text: 'Registration created successfully', color: 'success' });
          this.showRedirectVaccineCheckoutModal = true;
          //clear form
          //this.reset();
        });
      }, 400);
    },
    reset() {
      this.showConsentForm = true;
      this.formData = JSON.parse(this.formDataPrestine);
      this.patientCaseId = null;
      this.birthDate = null;
      this.noEmail = false;
    },
    nextStepConsentForm(consentData) {
      this.showConsentForm = false;
      this.consentFormData = consentData;
      window.scrollTo(0, 0);
    },
    resetRegistrationForm() {
      this.reset();
    }
  }
};
</script>

<style lang="scss">
.title-border {
  border: 1px solid #366baf !important;
}
</style>